.our-team {
    padding: 20px 15px 30px;
    background: #fff;
    border-radius: 15px;
    text-align: center;
    border: 1px white;
    box-shadow: 0 4px 8px 0 grey;
}

.our-team .pic {
    display: inline-block;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 10px;
    margin-bottom: 25px;
    transition: all 0.5s ease 0s;
}

.our-team:hover .pic {
    background: #4dd0e1;
    border-radius: 50%;
}

.pic img {
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.our-team .title {
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: #2e282a;
    text-transform: uppercase;
    margin: 0 0 7px 0;
}

.our-team .post {
    display: block;
    font-size: 15px;
    color: #4dd0e1;
    text-transform: capitalize;
    margin-bottom: 15px;
}

.our-team .read {
    display: block;
    font-size: 15px;
    color: #4dd0e1;
    text-transform: capitalize;
    margin-bottom: 15px;
    text-align: left;
}

.our-team .social {
    padding: 0;
    margin: 0;
    list-style: none;
}

.our-team .social li {
    display: inline-block;
    margin-right: 5px;
}

.our-team .social li a {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    font-size: 15px;
    color: #4dd0e1;
    border: 1px solid #4dd0e1;
    transition: all 0.5s ease 0s;
}

.our-team:hover .social li a {
    background: #4dd0e1;
    color: #fff;
}

@media only screen and (max-width: 990px) {
    .our-team {
        margin-bottom: 30px;
    }
}

#more {
    display: none;
}

.more {
    display: none;
}